<template>
  <v-container fluid>
    <v-row no-gutters>
      <!-- Main Card -->
      <v-col cols="12" v-if="isLoaded" class="mt-6">
        <v-card class="mx-2">
          <v-row no-gutters>
            <v-col cols="12" class="mt-2">
              <v-row justify="space-between" no-gutters>
                <div class="mx-2">
                  <h1>{{ item.name }}</h1>
                </div>
                <div class="mx-2">
                  <!--v-chip>{{item.type}}</v-chip-->
                  <v-btn icon @click="setShowMainEditDialog(true)">
                    <v-icon>fas fa-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="setShowDeleteDialog(true)">
                    <v-icon>far fa-trash</v-icon>
                  </v-btn>
                </div>
              </v-row>
            </v-col>

            <v-col cols="12" class="ma-2">
              <v-row no-gutters>
                <!-- Account -->
                <v-col cols="12">
                  <span>{{ $t("collections.labels.account") }} :</span>
                  <AccountLink :account="item.account" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- Tabs card -->
      <v-col cols="12" class="mt-6">
        <v-card>
          <v-tabs class="mx-4" v-model="tab" @change="switchTab" grow>
            <v-tab
              class="text-transform-none font-size-14"
              href="#tab-1"
              :exact="true"
            >
              {{ $t("collections.studies.title") }}
            </v-tab>

            <v-tab
              class="text-transform-none font-size-14"
              href="#tab-2"
              :exact="true"
            >
              {{ $t("collections.specifications.title") }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="ma-4">
            <!-- Studies -->
            <v-tab-item value="tab-1">
              <CommonStudiesTab v-if="isLoaded" :brandCollection="item" />
              <Skeleton v-else type="card" :occurrence="1" />
            </v-tab-item>

            <!-- Specifications -->
            <v-tab-item value="tab-2">
              <CollectionSpecificationsTab
                v-if="isLoaded"
                :brandCollection="item"
                @refresh="getData"
              />
              <Skeleton v-else type="card" :occurrence="1" />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <!-- Collection main dialog -->
    <CollectionMainDialog
      v-if="showDialog.main"
      :value="showDialog.main"
      :item="item"
      @close="(needRefresh) => setShowMainEditDialog(false, needRefresh)"
    />

    <!-- Collection delete dialog -->
    <DeleteDialog
      v-if="showDialog.delete"
      :value="showDialog.delete"
      :title="$t('collections.deleteDialog.title')"
      @submit="deleteItem"
      @close="setShowDeleteDialog(false)"
    />
  </v-container>
</template>

<script>
import formatter from "@/mixins/formatter";
import tabsUtils from "@/mixins/tabs-utils";

export default {
  name: "CollectionView",

  components: {
    CollectionSpecificationsTab: () =>
      import("@/components/Collections/CollectionSpecificationsTab"),
    Skeleton: () => import("@/components/Common/Cards/Skeleton"),
    CommonStudiesTab: () =>
      import("@/components/Common/Studies/CommonStudiesTab"),
    AccountLink: () => import("@/components/Common/Accounts/AccountLink"),
    DeleteDialog: () => import("@/components/Common/DeleteDialog"),
    CollectionMainDialog: () =>
      import("@/components/Collections/CollectionMainDialog"),
  },

  mixins: [formatter, tabsUtils],

  data() {
    return {
      showDialog: {
        main: false,
        delete: false,
        addProduct: false,
        editPicture: false,
      },

      isLoaded: false,
      isLoading: {
        btnSave: false,
        btnPictureDelete: false,
      },

      item: null,
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.isLoaded = false;
      this.isLoading.btnSave = false;

      this.$http
        .get(`/brand-collections/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.item = res.data;
          this.$store.commit("pageHead/setTitle", `${this.item.name}`);
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit("toast/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },
    deleteItem() {
      this.$http
        .delete(`/brand-collections/${this.item.id}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("collections.deleteDialog.success")
          );
          this.setShowDeleteDialog(false);
          this.$router.push({ name: "Collections" });
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtnSave = false;
        });
    },

    setShowMainEditDialog(val, reloadData = false) {
      if (reloadData) this.getData();

      this.showDialog.main = val;
    },

    setShowDeleteDialog(val, reloadData = false) {
      if (reloadData) this.getData();

      this.showDialog.delete = val;
    },

    setShowProductAddDialog(val, reloadData = false) {
      if (reloadData) this.getProducts();
      this.showDialog.addProduct = val;
    },
  },
};
</script>

<style scoped></style>
