export default {
  data() {
    return {
      itemsPerPage: [20, 50],
      totalItems: 0,
      items: [],
      pagination: {},
      loading: true,
      headers: [],
      search: "",
      sortBy: null,
      descending: false,
      savePaginationURL: false,
    };
  },

  created() {
    this.onCreatedPagination();
  },

  methods: {
    onCreatedPagination() {
      if (this.savePaginationURL) {
        if (
          this.$route.query.page &&
          this.pagination.page !== Number(this.$route.query.page)
        ) {
          this.pagination.page = Number(this.$route.query.page);
        }

        if (
          this.$route.query.itemsPerPage &&
          this.pagination.itemsPerPage !==
            Number(this.$route.query.itemsPerPage) &&
          this.itemsPerPage.includes(Number(this.$route.query.itemsPerPage))
        ) {
          this.pagination.itemsPerPage = Number(this.$route.query.itemsPerPage);
        }
        if (
          this.$route.query.search &&
          this.$route.query.search !== this.search
        ) {
          this.search = this.$route.query.search;
        }
      }
    },

    searchTrigger() {
      this.pagination = { ...this.pagination };
    },

    clearInput() {
      this.search = "";
      this.searchTrigger();
    },

    getDataFromAPI() {
      this.loading = true;

      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        if (this.search == null) this.search = "";

        let url = `${this.URL_API.GET_ALL}?offset=${
          (this.pagination.page - 1) * this.pagination.itemsPerPage
        }&limit=${this.pagination.itemsPerPage}&search=${this.search}${
          typeof this.filtersURL === "string" ? this.filtersURL : ""
        }`;
        //&sortDesc[]=${this.pagination.sortDesc}&sortBy=${this.pagination.sortBy}
        let params = {};

        if (this.filters) {
          for (const filter of Object.keys(this.filters)) {
            if (Array.isArray(this.filters[filter])) {
              // console.log(this.filters[filter])
              //const st = Object.keys(this.filters[filter]).filter((el) => this.filters[filter][el]);
              //if (Object.keys(this.filters[filter]).length >= st.length)
              params[filter] = this.filters[filter];
            } else {
              params[filter] = this.filters[filter];
            }
          }
        }

        params["sortBy"] = this.pagination.sortBy;
        params["sortDesc"] = this.pagination.sortDesc;

        // console.log(params)
        this.$http
          .get(url, {
            params: params,
            headers: {
              Authorization: "Bearer " + this.$session.get("jwt"),
            },
          })
          .then((res) => {
            this.loading = false;
            return resolve({
              items: res.data.data,
              totalItems: res.data.total,
            });
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },
  },

  watch: {
    pagination: {
      handler() {
        if (this.savePaginationURL) {
          if (
            this.pagination.page &&
            this.pagination.page !== Number(this.$route.query.page)
          ) {
            this.$router.push({
              query: { ...this.$route.query, page: this.pagination.page },
              hash: this.$route.hash,
            });
          }

          if (
            this.pagination.itemsPerPage &&
            this.pagination.itemsPerPage !==
              Number(this.$route.query.itemsPerPage)
          ) {
            this.$router.replace({
              query: {
                ...this.$route.query,
                itemsPerPage: this.pagination.itemsPerPage,
              },
              hash: this.$route.hash,
            });
          }

          if (!!this.search && this.search !== this.$route.query.search) {
            this.$router.push({
              query: { ...this.$route.query, search: this.search },
              hash: this.$route.hash,
            });
          }
        }

        this.getDataFromAPI()
          .then((data) => {
            this.loading = false;
            this.items = data.items;
            this.totalItems = data.totalItems;

            //this.$store.commit("data/setPagination", this.pagination);
          })
          .catch(() => {
            this.$store.commit("toast/showError", this.$t("common.errors.500"));
          });
      },
      deep: true,
    },
  },

  computed: {
    params: () => {},
  },
};
