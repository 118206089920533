import countries from '../translations/constants/countries.json'
import languages from "../translations/constants/languages.json"
import currencies from '../translations/constants/currencies.json';

import {DateTime} from "luxon";

export default {

  data() {
    return {
      currencies
    }
  },

  methods: {

    formatDates(date1, date2 = null) {
      if (date2)
        return `${this.$t('common.misc.from')} ${this.formatDate(date1)} ${this.$t('common.misc.to')} ${this.formatDate(date2)}`;
      else
        return `${this.$t('common.misc.from2')} ${this.formatDate(date1)}`;
    },

    formatDate(date, inputFormat = "ISO", outputFormat = "DATE_SHORT", outputLocaleString = true) {
      if (date) {
        if (inputFormat === "ISO") {
          if (outputLocaleString)
            return DateTime.fromISO(date).toLocaleString(DateTime[outputFormat]);
          return DateTime.fromISO(date).toFormat(outputFormat);
        }
      }
      return "";
    },

    formatDatetime(datetime, inputFormat = "ISO", outputFormat = "DATETIME_SHORT") {
      if (datetime) {
        if (inputFormat === "ISO") {
          return DateTime.fromISO(datetime).toLocaleString(DateTime[outputFormat]);
        }
      }
      return "";
    },

    countryFormatter(country) {
      return (countries.hasOwnProperty(country)) ? countries[country]['name'] : "";
    },

    langFormatter(lang) {
      return (languages.hasOwnProperty(lang)) ? languages[lang]['name'] : "";
    },

    languagesFormatter(languages) {
      let retArr = [];
      if (languages && Array.isArray(languages))
      {
        for (let lang of languages)
          retArr.push(lang.English);
      }
      return retArr.join(", ");
    },

    formatKeyPaths(keys) {
      let retStr = "";

      for (let i  = 0; i < keys.length; i++)
      {
        if (typeof keys[i] === "string")
        {
          if (i > 0)
            retStr += ".";
          retStr += keys[i];
        }
        else if (typeof keys[i] === "number")
        {
          retStr += `[${keys[i]}]`;
        }
      }

      return retStr;
    },

    amountFormatter(val, currency, formatDiscount = false) {
      let retStr = "";

      if (val)
        retStr = this.$options.filters.currency(val);
      else
        retStr = "0";

      if (currency && this.currencies.hasOwnProperty(currency))
      {
        const symbol = this.currencies[currency].symbol;

        if (currency === "USD")
          retStr = `${symbol} ${retStr}`;
        else
          retStr = `${retStr} ${symbol}`
      }
      else
        retStr = `${retStr} ${this.currencies['EUR'].symbol}`;

      if (formatDiscount)
        retStr = `( ${retStr} )`;

      return retStr;
    },

    percentageFormatter(val, format = "0%") {
      let retStr = "0%";

      if (val) {
        if (parseFloat(val) >= 0) {
          retStr = `+${this.$options.filters.numeralFormat(val, format)}`;
        }
        else
          retStr = `${this.$options.filters.numeralFormat(val, format)}`;
      }

      return retStr;
    },

    numberFormatter(val) {
      if (val)
        return this.$options.filters.numeralFormat(val);
      return "";
    },

    formatMinutesToHours(val) {
      if (val) {
        const hours = Math.trunc(val/60);
        let minutes = val%60;
        if (minutes === 0)
          minutes = "";

        return `${hours}h${minutes}`;
      }
      return "0h";
    },

    datetimeEventsFormatter(date) {
      if (date) {
        const now = DateTime.now();

        if (now.diff(DateTime.fromISO(date), 'years').years !== 0)
          return `${DateTime.fromISO(date).toFormat("D, HH:mm")}`;

        return `${DateTime.fromISO(date).toFormat("D, HH:mm")}`;
      }

      return "";
    },

    formatterRound(value, precision) {
      const multiplier = Math.pow(10, precision || 0);
      return Math.round(value * multiplier) / multiplier;
    }
  }
}
