export default {
  data() {
    return {
      tab: "tab-1",
    };
  },

  created() {
    if (this.$route.hash) this.tab = this.$route.hash.replace("#", "");
  },

  methods: {
    switchTab(val) {
      this.$router.replace({ hash: val });
    },
  },

  /*
  watch: {
    "$route.hash": {
      handler: function(search) {},
      deep: true,
      immediate: true,
    },
  },
  */
};
